<template>
	<section class="vc vc--material">
		<div class="container">
			<div class="vc__content">
				<div class="vc__column vc__column--material">
					<p class="vc__title vc__title--material">{{ textContent.title }}</p>
					<p class="vc__text" v-html="textContent.text"></p>

				</div>
				<div class="vc__slider vc__slider--material">
					<div class="vc__slide vc__slide--material slide">
								<div class="slide__media slide__media--material">
									<div v-if="isVideoActive" class="slide__video vc__video">
										<iframe
												:src="`https://www.youtube.com/embed/${video.videoId}?autoplay=1&mute=1`"
												title="test"
												style="width: 100%; height: 100%"
												frameborder="0"
												allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
												allowfullscreen>
										</iframe>
									</div>
									<img v-if="!isVideoActive" class="slide__pic" :src="video.pic" :alt="video.text">
									<button v-if="!isVideoActive" class="slide__btn" @click="isVideoActive = true">
										<svg width="120" height="120" class="slide__btn-icon">
											<use xlink:href="~@/assets/img/sprite.svg#play"></use>
										</svg>
									</button>

								</div>
						</div>
				</div>
			</div>
		</div>

	</section>
</template>


<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Navigation } from 'swiper'
import 'swiper/css';
import 'swiper/css/pagination'

export default {
	name: "VideoCarousel",
	props: {
		textContent: {
			type: Object,
			default: () => ({
				title: '',
				text: ''
			})
		},
		video: {
			type: Object,
			default: () => ({})
		},
	},
	components: {
		Swiper,
		SwiperSlide,
	},
	data() {
		return {
			modules: [Pagination, Navigation],
			navigation: {
				nextEl: '.vc__slider-btn--next',
				prevEl: '.vc__slider-btn--prev',
			},
			isVideoActive: false,
			breakpoints: {
				320: {
					spaceBetween: 13,
				},
				// when window width is >= 7680px
				768: {
					spaceBetween: 60
				},
				// when window width is >= 1024px

			},
		}
	}
}
</script>

<style scoped>

</style>
