import { object, string } from "yup";
const phoneRegEx = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
const emailRegEx = /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;
const alphaNumRegEx = /^[A-Za-z0-9\s]+$/;
const textRegEx = /^[a-zA-Z0-9?$@#()'!,+\-=_:.&€£*%\s]+$/;

const formSchema = object({
    name: string().required('required field').min(2).max(50).matches(alphaNumRegEx, { message: 'incorrect characters' }),
    email: string().required('email required').matches(emailRegEx, { message: 'wrong email' }),
    //phone: string().required('phone required').matches(phoneRegEx, { message: 'wrong phone' }),
    comment: string().max(3000).matches(textRegEx, { message: 'incorrect characters' }),
})

export {formSchema}
