import axios from "axios";
const axiosInstance = axios.create({
    timeout: 1000,
    withCredentials: true
});

export default {
    async sendRequest({name, email, phone, comment = '', recaptchaToken}) {
        const formData = new FormData()
        formData.append('api_action', 'sendmail')
        formData.append('name', name)
        formData.append('email', email)
        formData.append('phone', phone)
        formData.append('recaptcha', recaptchaToken)
        if (comment) {
            formData.append('comment', comment)
        }
        return axiosInstance.post('/api.php', formData).catch(e => console.log(e))
    }
}
