<template>
	<section class="how">
		<div class="container">
			<div class="how__content">
				<h2 class="how__title">How it works</h2>
				<swiper
					class="how__list"
					slides-per-view="auto"
					:watch-overflow="true"
					:modules="modules"
					:pagination="{ type: 'bullets' }"
					:breakpoints="breakpoints"
					:simulate-touch="simulateTouch"
				>
					<swiper-slide
						v-for="(slide, i) in slides"
						class="how__list-item"
						:key="`slide_${i}`"
					>
						<div class="how__list-item-img-wrap">
							<img :src="slide.pic" alt="" class="how__list-item-icon" />
						</div>
						<p class="how__list-item-text" v-html="slide.text" />
					</swiper-slide>
				</swiper>

				<div class="how__afterword">
					<p>
						The tool is not recommended for independent individual use. We
						highly recommend individuals get in touch with a local support
						organization before starting the scan to get advice and support if
						stalkerware is found on their devices.
					</p>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Navigation } from 'swiper'
import 'swiper/css';
import 'swiper/css/pagination'
import 'swiper/css/navigation'

export default {
	name: "HowWorks",
	components: {
		Swiper,
		SwiperSlide,
	},
	data() {
		return {
			modules: [Pagination, Navigation],
			simulateTouch: false,
			navigation: {
				nextEl: '.partners__slider-btn--next',
				prevEl: '.partners__slider-btn--prev',
			},
			breakpoints: {
				320: {
					spaceBetween: 25,
					centeredSlides: true
				},
				// when window width is >= 7680px
				768: {
					spaceBetween: 67,
					centeredSlides: true
				},
				// when window width is >= 1024px
				1280: {
					spaceBetween: 67,
					slidesPerView: 4,
					centeredSlides: false,
					disabled: true
				}
			},
			slides: [
				{
					pic: require('@/assets/img/how_pic4.svg'),
					text: 'TinyCheck scans a device’s outgoing traffic, using a regular Wi-Fi connection, and identifies interactions with known sources, such as stalkerware-related servers'
				},
				{
					pic: require('@/assets/img/how_pic1.svg'),
					text: 'TinyCheck can be used to check any device and on any platform, including iOS, Android or any other OS'
				},
				{
					pic: require('@/assets/img/how_pic2.svg'),
					text: 'It doesn’t require installation on a user’s device because it works separately (on a Raspberry Pi) to avoid being detected by a stalker'
				},
				{
					pic: require('@/assets/img/how_pic3.svg'),
					text: 'TinyCheck is a free, safe and <span>open-source tool</span> that can be used by NGOs and police units to help support victims of digital stalking'
				},
			]
		}
	}
}
</script>

<style scoped></style>
