<template>
  <div class="about">
    <hero-section :title="title" :intro="intro" modificator="about" />
    <div class="container">
      <p class="about__title">What makes TinyCheck unique?</p>
      <ul class="about__list">
        <li class="about__list-item">
          It enables a device to be scanned for the presence of stalkerware in a
          simple, quick, and non-invasive way.
        </li>
        <li class="about__list-item">
          It doesn’t inform the perpetrator that a scan is being performed
        </li>
        <li class="about__list-item">
          It can check all devices, regardless of their OS
        </li>
        <li class="about__list-item">The software is free and open-source</li>
        <li class="about__list-item">The hardware is affordable</li>
      </ul>
      <p class="about__text">
        According to anonymized <a href="https://securelist.com/the-state-of-stalkerware-in-2021/106193/" target="_blank">data</a> gathered by Kaspersky, stalkerware affected
        more than 32,000 mobile users globally in 2021.  But this is only the
        tip of the iceberg. The <a href="https://stopstalkerware.org/" target="_blank">Coalition Against Stalkerware</a> estimates that the
        malicious software’s usage could excees one million instances per year.
        Kaspersky’s2021 survey, conducted in in 21 countries, also revealed that
        30% of respondents see no issue with monitoring their partner under
        certain circumstances.
      </p>
      <p class="about__text">
        The fight against stalkerware is far from over, but more data and tools
        are becoming available to help. TinyCheck’s <a href="https://stopstalkerware.org/" target="_blank">source code</a> is constantly
        being updated and improved, thanks to feedback from its users, and to
        the many contributors who develop new features and update the list of
        Indicators of Compromise (IOC). Special thanks to the Kaspersky team,
        Félix Aimé, Echap, Etienne Maynier, Cian Heasley and others.
      </p>
      <p class="about__text">
        Do you want to help? Contact us and join our efforts!
      </p>
      <!-- <a class="btn hero__btn" href="#contact" @click.prevent="onClickContacts">Contact us</a> -->
    </div>
    <feedback-form theme="light" />
  </div>
</template>
<script>
import FeedbackForm from "@/components/FeedbackForm";
import HeroSection from "@/components/HeroSection";
import MaterialVideo from "@/components/MaterialVideo";

export default {
  name: "MaterialView",
  components: { FeedbackForm, HeroSection, MaterialVideo },
  data() {
    return {
      title: "Why was TinyCheck developed?",
      intro: 'TinyCheck was developed in 2020 to help cyberstalking victims. A women’s shelter in France told Kaspersky that the number of stalkerware victims it was supporting was increasing. The company wanted to help but initially it was unsure how to tackle the issue from a technological point of view. It needed an untraceable solution to scan devices that would be easy to use and could help gather evidence.' + '<br><br>' +
      'After extensive discussions with the women’s shelter to understand its requirements, and several hours of coding by security researchers from Kaspersky’s Global Research and Analysis Team (GReAT) - primarily Félix Aimé @Felixaimé - TinyCheck was born.',
      about: {},
      methods: {
      onClickContacts(e) {
        const anchor = document.querySelector(e.target.getAttribute("href"));

        this.menuVisible = false;
        this.$smoothScroll({
            updateHistory: false,
            scrollTo: anchor,
        });
        }
      }
    };
  },
};
</script>