<template>
	<section class="partners">
		<div class="container">
			<div class="partners__content">
				<p class="partners__title">Our Partners and Contributors</p>
				<div class="partners__slider">
					<swiper
							slides-per-view="1"
							:modules="modules"
							:pagination="{type: 'bullets'}"
							:navigation="navigation"
					>
						<swiper-slide v-for="(slide, idx) in slides" :key="idx">
							<div class="partners__slide">
								<a
									v-if="slide.link"
									:href="slide.link"
									class="partners__slide-pic"
									target="_blank"
								>
									<img :src="slide.pic" />
								</a>
								<div v-else class="partners__slide-pic">
									<img :src="slide.pic" />
								</div>
								<div class="partners__slide-text">
									<svg v-if="slide.hasQuotas" class="partners__slide-quotas">
										<use xlink:href="~@/assets/img/sprite.svg#quotas"></use>
									</svg>
									<div v-html="slide.html"></div>
								</div>
							</div>
						</swiper-slide>
					</swiper>
					<button class="partners__slider-btn partners__slider-btn--prev">
						<svg width="24" height="24">
							<use xlink:href="~@/assets/img/sprite.svg#arrow-left"></use>
						</svg>
					</button>
					<button class="partners__slider-btn partners__slider-btn--next">
						<svg width="24" height="24">
							<use xlink:href="~@/assets/img/sprite.svg#arrow-left"></use>
						</svg>
					</button>
				</div>
			</div>
		</div>

	</section>
</template>


<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Navigation } from 'swiper'
import 'swiper/css';
import 'swiper/css/pagination'
import 'swiper/css/navigation'

export default {
	name: "OurPartners",
	props: {
		slides: {
			type: Array,
			default: () => []
		}
	},
	components: {
		Swiper,
		SwiperSlide,
	},
	data() {
		return {
			modules: [Pagination, Navigation],
			navigation: {
				nextEl: '.partners__slider-btn--next',
				prevEl: '.partners__slider-btn--prev',
			}
		}
	}
}
</script>

<style scoped>

</style>
