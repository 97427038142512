import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Maska from "maska";
import VueSmoothScroll from 'vue3-smooth-scroll'
import '@/assets/scss/app.scss'
import { VueReCaptcha } from 'vue-recaptcha-v3'

createApp(App)
    .use(router)
    .use(Maska)
    .use(VueSmoothScroll)
    .use(VueReCaptcha, {siteKey: "6LcNYHwjAAAAAGuF8fsc-TIrPBONIz4UO94Rkgqo" || null})
    .mount('#app')
