<template>
  <div class="faq">
		<hero-section :title="title" :intro="intro" modificator="materials" />
		<section class="faq">
			<div class="container">
				<h2 class="faq__title">Frequently Asked Questions</h2>
				<ul class="faq__list">
					<li :ref="`faq_item_${idx}`" class="faq__item" v-for="(item, idx) in faq">
						<div class="faq__question-wrap" @click="toggle(idx, `faq_item_${idx}`)">
							<button class="faq__btn" :class="{active: idx === activeFaqItem}">
								<svg class="faq__btn-icon" v-if="idx === activeFaqItem">
									<use xlink:href="~@/assets/img/sprite.svg#minus"></use>
								</svg>
								<svg class="faq__btn-icon" v-else>
									<use xlink:href="~@/assets/img/sprite.svg#plus"></use>
								</svg>
							</button>
							<p class="faq__question" v-html="item.question"></p>
						</div>
						<div class="faq__answer" v-if="idx === activeFaqItem" v-html="item.answer"></div>
					</li>
				</ul>
			</div>
		</section>

		<feedback-form />
  </div>
</template>
<script>
import FeedbackForm from "@/components/FeedbackForm";
import HeroSection from "@/components/HeroSection";

export default {
	name: 'FaqView',
	components: {FeedbackForm, HeroSection},
	data() {
		return {
			title: 'Ask&nbsp;us <br>anything',
			intro: 'Below are the Top Frequently Asked Questions. If&nbsp;you didn&rsquo;t find what you were looking for, feel free to&nbsp;reach out!',
			activeFaqItem: 0,
			faq: [
				{
					question: 'What is&nbsp;stalkerware?',
					answer: `
						<p>Stalkerware refers to&nbsp;software programs that enable a&nbsp;person to&nbsp;infiltrate another person&rsquo;s mobile device to&nbsp;spy on&nbsp;their private life. The perpetrator can remotely monitor all device activities, including web history, geolocation, text messages, photos, voice calls and more. These programs are easy to&nbsp;buy and install, and they run hidden in&nbsp;the background without the affected person knowing or&nbsp;giving consent.</p>
						<p>This type of&nbsp;software is&nbsp;often used as&nbsp;a&nbsp;tool in&nbsp;abusive relationships. </p>
					`
				},
				{
					question: 'What is&nbsp;TinyCheck?',
					answer: `
						<p>TinyCheck is&nbsp;a&nbsp;free and open-source tool, developed and supported by&nbsp;Kaspersky experts and the IT&nbsp;Security community (special thanks go&nbsp;to <a href="https://twitter.com/felixaime" target="_blank">@felixaime</a>, <a href="https://twitter.com/tenacioustek" target="_blank">@tenacioustek</a>, <a href="https://twitter.com/nscrutables" target="_blank">@nscrutables</a> and <a href="https://twitter.com/emilien" target="_blank">@Emilien</a>). The solution was created to&nbsp;help organizations working with victims of&nbsp;domestic violence. TinyCheck aims to&nbsp;protect privacy through the detection of&nbsp;stalkerware in&nbsp;a&nbsp;simple, quick and non-invasive way</p>
						<p>TinyCheck is&nbsp;now being tested by&nbsp;various other actors&nbsp;&mdash; associations, companies, police, journalists etc. &mdash;&nbsp;in&nbsp;numerous countries so&nbsp;it&nbsp;can be&nbsp;more widely deployed and help even more victims.</p>
						<p>The software is&nbsp;available now on&nbsp;<a href="https://github.com/KasperskyLab/TinyCheck" target="_blank">Github.</a></p>
					`
				},
				{
					question: 'Why is&nbsp;TinyCheck unique?',
					answer: `
						<p>TinyCheck is&nbsp;special in&nbsp;its ability to&nbsp;detect stalkerware and then inform the user without making the perpetrator aware that a&nbsp;check has been carried out. While other security solutions can also check and alert the user to&nbsp;stalkerware, they need to&nbsp;be&nbsp;installed on&nbsp;the device, which then risks the perpetrator also being alerted. However, TinyCheck avoids this issue by&nbsp;not needing anything to&nbsp;be&nbsp;installed on&nbsp;the device to&nbsp;perform a&nbsp;check.</p> <p>In&nbsp;addition, TinyCheck can be&nbsp;used on&nbsp;any device, regardless of&nbsp;whether it&nbsp;is&nbsp;an&nbsp;iOS or&nbsp;Android device or&nbsp;one using any other OS. </p> <p>These two differentiators address two of&nbsp;the major issues in&nbsp;the fight to&nbsp;protect users against stalkerware.</p>
					`
				},
				{
					question: 'How does TinyCheck work?',
					answer: `
						<p><u>Device scan:</u> Using a&nbsp;regular Wi-Fi connection, TinyCheck scans a&nbsp;mobile device&rsquo;s outgoing traffic and identifies interactions with known sources, including spyware-related servers. TinyCheck can be&nbsp;used in&nbsp;several different ways:</p>
						<ul>
						<li>Over a&nbsp;network&nbsp;&mdash; TinyCheck is&nbsp;installed onto the network and accessed from a&nbsp;workstation via a&nbsp;browser.</li>
						<li>Kiosk mode&nbsp;&mdash; TinyCheck is&nbsp;used as&nbsp;a&nbsp;kiosk to&nbsp;allow users to&nbsp;test their own devices.</li>
						<li>Fully standalone&nbsp;&mdash; TinyCheck is&nbsp;used through a&nbsp;power bank, two Wi-Fi interfaces or&nbsp;a&nbsp;4G&nbsp;dongle and a&nbsp;small touch screen, allowing users to&nbsp;scan any device anywhere.</li>
						</ul>
						<p><u>Digital evidence:</u> After the scan, TinyCheck provides victims with the option to&nbsp;download a&nbsp;simplified report and a&nbsp;technical report, including all the logs, onto a&nbsp;USB key that they can present to&nbsp;the police or&nbsp;any trusted actor.</p>
						<p><u>IOCs management:</u> TinyCheck alerts are based on&nbsp;the regularly updated list of&nbsp;Indicators of&nbsp;Compromise (these include pieces of&nbsp;digital forensic evidence, showing potential intrusions and malicious activity in&nbsp;the system) provided by&nbsp;Kaspersky and trusted contributors. Additionally, anyone can add their own indicators of&nbsp;compromise to&nbsp;the list through the TinyCheck backend.</p>
						<p><u>Privacy:</u> No&nbsp;data intercepted during a&nbsp;device scan is&nbsp;shared with Kaspersky or&nbsp;anyone else. The data is&nbsp;deleted when a&nbsp;new scan is&nbsp;initiated or&nbsp;at&nbsp;the point of&nbsp;shutdown (more information <a href="https://github.com/KasperskyLab/TinyCheck/wiki/Privacy" target="_blank">here</a>).</p>

					`
				},
				{
					question: 'How effective is&nbsp;TinyCheck at&nbsp;detecting stalkerware?',
					answer: `
						<p>According to&nbsp;the <a href="https://www.av-comparatives.org/wp-content/uploads/2021/05/avc_stalkerware_2021.pdf" target="_blank">latest independent test by&nbsp;AV&nbsp;Comparatives,</a> TinyCheck has high detection capabilities with&nbsp;75% of&nbsp;stalkerware samples being detected. It&nbsp;is&nbsp;important to&nbsp;note that TinyCheck is&nbsp;not as&nbsp;effective as&nbsp;a&nbsp;&laquo;regular&raquo; mobile security solution. Therefore, it&nbsp;is&nbsp;necessary to&nbsp;distinguish the <a href="https://www.kaspersky.com/blog/stalkerware-detection-tactics/" target="_blank">different levels of&nbsp;detection effectiveness:</a></p>
						<ol>
						<li><u>Level&nbsp;0: Following infection signs</u>&nbsp;&mdash; for instance, a&nbsp;fast-draining battery used up&nbsp;by&nbsp;unknown or&nbsp;suspicious apps; increased mobile data traffic; or&nbsp;newly-installed applications with suspicious access to&nbsp;using and tracking locations, text messages and other personal activities  Low level of&nbsp;certainty, no&nbsp;installation required</li>
						<li><u>Level&nbsp;1: TinyCheck</u>&nbsp;&mdash; only analyzes a&nbsp;device&rsquo;s external communications  Medium level of&nbsp;certainty, no&nbsp;installation required</li>
						<li><u>Level&nbsp;2: Mobile security solution, like Kaspersky Security &amp;&nbsp;VPN</u>&nbsp;&mdash; includes analysis of&nbsp;applications installed on&nbsp;the device, heuristic/behavioral analysis, etc.  High level of&nbsp;certainty, installation required</li>
						<li><u>Level&nbsp;3: Full &laquo;dump&raquo; of&nbsp;the device</u>&nbsp;&mdash; this is&nbsp;a&nbsp;longer and more complex method used by&nbsp;law enforcement agencies  High level of&nbsp;certainty, professional software required</li>
						</ol>
						<p>In&nbsp;summary, TinyCheck is&nbsp;useful for mitigating doubt or&nbsp;as&nbsp;a&nbsp;preliminary collection of&nbsp;digital evidence for victims with a&nbsp;high risk of&nbsp;family conflict escalation.</p>
					`
				},
				{
					question: 'Is&nbsp;it&nbsp;safe to&nbsp;use TinyCheck?',
					answer: `
						<p>TinyCheck does not read the content of&nbsp;your communications (SMS, emails, etc.) but, rather, checks which online servers/IPs your smartphone communicates with. In&nbsp;other words, TinyCheck will not know who you are speaking with or&nbsp;what you are discussing.</p>
            <p>The network capture of&nbsp;your analyzed device (e.g. smartphone, tablet, laptop, etc.) is&nbsp;not shared anywhere: neither Kaspersky nor any other party will receive this data. All the analysis is&nbsp;done locally. The data (full packet capture, logs and PDF report) will only be&nbsp;present on&nbsp;either:</p>
            <ul>
            <li>Your USB stick, which you will be&nbsp;asked to&nbsp;insert at&nbsp;the end of&nbsp;the process</li>
            <li>Your computer, if&nbsp;the user of&nbsp;TinyCheck is&nbsp;running the tool in&nbsp;a&nbsp;browser from a&nbsp;remote workstation (read more here: <a href="https://github.com/KasperskyLab/TinyCheck/wiki/How-to-to-use-it" target="_blank">Saving the capture</a>)</li>
            </ul>
					`
				},
				{
					question: 'Does Kaspersky obtain my&nbsp;data when&nbsp;I scan my&nbsp;smartphone with TinyCheck?',
					answer: `
						<p>No&nbsp;data is&nbsp;sent to&nbsp;Kaspersky or&nbsp;anyone else. Data privacy and user security are at&nbsp;the heart of&nbsp;the project.</p>
					`
				},
				{
					question: 'Where can&nbsp;I buy TinyCheck and how much does it&nbsp;cost?',
					answer: `
						<p>The source code of&nbsp;TinyCheck can be&nbsp;downloaded for free from <a href="https://github.com/KasperskyLab/TinyCheck" target="_blank">GitHub</a>. It&nbsp;is&nbsp;then up&nbsp;to&nbsp;the user to&nbsp;get the hardware (for instance, Raspberry Pi&nbsp;and possibly a&nbsp;touch screen and Wi-Fi key) from a&nbsp;local supplier. The cost of&nbsp;the hardware is&nbsp;about 200&nbsp;euros, but this cost can be&nbsp;reduced. See a&nbsp;few working hardware configurations <a href="https://github.com/KasperskyLab/TinyCheck/wiki/List-of-working-hardware-configs" target="_blank">here</a>.</p>
					`
				},
				{
					question: 'Can I&nbsp;build a&nbsp;TinyCheck device on&nbsp;my&nbsp;own?',
					answer: `
						<p>It&nbsp;is&nbsp;possible for a&nbsp;tech-savvy person to&nbsp;assemble TinyCheck, as&nbsp;it&nbsp;relies on&nbsp;Raspberry Pi. However, we&nbsp;highly recommend individuals get in&nbsp;touch with a&nbsp;local NGO before starting the scan to&nbsp;get proper support and create an&nbsp;escape plan in&nbsp;case stalkerware is&nbsp;found on&nbsp;their devices.</p>
            <p>Organizations can get in&nbsp;touch with&nbsp;us via <a href="mailto:info@tiny-check.com">info@tiny-check.com</a> and we&nbsp;can provide them with a&nbsp;guidance document on&nbsp;how to&nbsp;assemble TinyCheck. </p>

					`
				},
				{
					question: 'What kind of&nbsp;support does Kaspersky provide to&nbsp;organizations supporting the victims of&nbsp;domestic violence?',
					answer: `
						<p>For the past few years, Kaspersky has worked with NGOs and police units globally by&nbsp;providing them with training on&nbsp;the technical aspects of&nbsp;tech-enabled abuse, helping them access and use TinyCheck and sharing information about stalkerware. </p>
            <p>In&nbsp;2019, Kaspersky co-founded the <a href="https://stopstalkerware.org/" target="_blank">Coalition Against Stalkerware</a> with nine other organizations. The Coalition now brings together more than 40&nbsp;organizations, from NGOs and digital rights organizations, to&nbsp;law enforcement agencies and private sector actors. The Coalition is&nbsp;a&nbsp;platform to&nbsp;exchange best practices and work together in&nbsp;the fight against stalkerware and tech-enabled abuse, providing the best possible support to&nbsp;victims. </p>

					`
				},
				{
					question: 'Is&nbsp;TinyCheck only for NGOs?',
					answer: `
						<p>While TinyCheck was initially developed for NGOs, its functionalities and ease of&nbsp;use mean that it&nbsp;can be&nbsp;useful to&nbsp;other stakeholders as&nbsp;well.</p>
            <p>Police forces and judicial entities are already testing the devices to&nbsp;provide better support to&nbsp;victims and to&nbsp;assist with investigation processes and the gathering of&nbsp;evidence.</p>
            <p>Additionally, TinyCheck can also be&nbsp;a&nbsp;useful tool for journalists who want to&nbsp;make sure their devices have not been compromised since it&nbsp;helps uncover all types of&nbsp;geo-tracking apps.</p>

					`
				},
				{
					question: 'Can TinyCheck be&nbsp;used for other types of&nbsp;spyware?',
					answer: `
					<p>Pegasus-related domains from Amnesty International&rsquo;s investigation were added to&nbsp;TinyCheck in&nbsp;August 2021, which means the tool can detect a&nbsp;device&rsquo;s communication with the Pegasus infrastructure. But beware&nbsp;&mdash; the effectiveness of&nbsp;TinyCheck remains limited for this type of&nbsp;spyware because the domains are changed regularly.</p>
					`
				},
				{
					question: 'Where is&nbsp;the stalkerware IOC database hosted?',
					answer: `
						<p>The database is&nbsp;hosted on&nbsp;GitHub with the following links:</p>
            <ul>
            <li><a href="https://raw.githubusercontent.com/KasperskyLab/TinyCheck/main/assets/iocs.json" target="_blank">https://raw.githubusercontent.com/KasperskyLab/TinyCheck/main/assets/iocs.json</a></li>
            <li><a href="https://raw.githubusercontent.com/Te-k/stalkerware-indicators/master/generated/indicators-for-tinycheck.json" target="_blank">https://raw.githubusercontent.com/Te-k/stalkerware-indicators/master/generated/indicators-for-tinycheck.json</a></li>
            <li><a href="https://raw.githubusercontent.com/KasperskyLab/TinyCheck/main/assets/whitelist.json" target="_blank">https://raw.githubusercontent.com/KasperskyLab/TinyCheck/main/assets/whitelist.json</a></li>
            </ul>
					`
				},
				{
					question: 'I&nbsp;want to&nbsp;help develop TinyCheck, how can I&nbsp;contribute?',
					answer: `
						<p>If&nbsp;you want to&nbsp;help develop the tool further, donate devices to&nbsp;NGOs or&nbsp;help&nbsp;us find new partners, please get in&nbsp;touch with our team. We&nbsp;look forward to&nbsp;new collaboration opportunities that will benefit victims everywhere in&nbsp;the world. You can contact&nbsp;us at&nbsp;<a href="mailto:info@tiny-check.com">info@tiny-check.com</a>.</p>

					`
				},
				{
					question: 'How can&nbsp;I get in&nbsp;touch with the team?',
					answer: `
						<p>Non-profit organizations and other interested entities can get in&nbsp;touch with&nbsp;us at&nbsp;<a href="info@tiny-check.com">info@tiny-check.com</a>.</p>
					`
				},

			]
		}
	},
	methods: {
		toggle(idx, ref) {
			this.activeFaqItem = this.activeFaqItem === idx ? null : idx;

      setTimeout(() => {
        this.$smoothScroll({
          updateHistory: false,
          scrollTo: this.$refs[ref][0],
        });
      }, 300);
		}
	}
}
</script>
