<template>
	<section class="vc">
		<div class="container">
			<div class="vc__content">
				<div class="vc__column">
					<p class="vc__title">{{ textContent.title }}</p>
					<p class="vc__text" v-html="textContent.text"></p>

				</div>
				<div class="vc__slider">
					<swiper
							slides-per-view="auto"
							:modules="modules"
							:pagination="{type: 'bullets'}"
							:navigation="navigation"
							:breakpoints="breakpoints"
					>
						<swiper-slide v-for="(slide, idx) in slides" :key="idx">
							<div class="vc__slide slide">
								<div class="slide__media">
									<div v-if="activeVideoSlideIdx === idx" class="slide__video vc__video">
										<iframe
												:src="`https://www.youtube.com/embed/${slide.videoId}?autoplay=1&mute=1`"
												title="test"
												style="width: 100%; height: 100%"
												frameborder="0"
												allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
												allowfullscreen>
										</iframe>
									</div>
									<img v-else class="slide__pic" :src="slide.pic" :alt="slide.text">
									<button v-if="activeVideoSlideIdx !== idx" class="slide__btn" @click="activeVideoSlideIdx = idx">
										<svg width="120" height="120" class="slide__btn-icon">
											<use xlink:href="~@/assets/img/sprite.svg#play"></use>
										</svg>
									</button>

								</div>
								<div class="slide__footer">
									<span class="slide__text">{{ slide.text }}</span>
									<span class="slide__nav">
											<span class="slide__nav-current">{{ idx > 9 ? idx+1 : `0${idx+1}`}}</span>
											<span> / </span>
											<span class="slide__nav-total">{{ slides.length > 9 ? slides.length : `0${slides.length}`}}</span>
										</span>
								</div>
							</div>
						</swiper-slide>
					</swiper>
					<div class="vc__slider-arrows">
						<button class="vc__slider-btn vc__slider-btn&#45;&#45;prev">
							<svg width="24" height="24">
								<use xlink:href="~@/assets/img/sprite.svg#arrow-left"></use>
							</svg>
						</button>
						<button class="vc__slider-btn vc__slider-btn&#45;&#45;next">
							<svg width="24" height="24">
								<use xlink:href="~@/assets/img/sprite.svg#arrow-left"></use>
							</svg>
						</button>
					</div>
				</div>
			</div>
		</div>

	</section>
</template>


<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Navigation } from 'swiper'
import 'swiper/css';
import 'swiper/css/pagination'

export default {
	name: "VideoCarousel",
	props: {
		textContent: {
			type: Object,
			default: () => ({
				title: '',
				text: ''
			})
		},
		slides: {
			type: Array,
			default: () => []
		}
	},
	components: {
		Swiper,
		SwiperSlide,
	},
	data() {
		return {
			modules: [Pagination, Navigation],
			navigation: {
				nextEl: '.vc__slider-btn--next',
				prevEl: '.vc__slider-btn--prev',
			},
			activeVideoSlideIdx: '',
			breakpoints: {
				320: {
					spaceBetween: 13,
				},
				// when window width is >= 7680px
				768: {
					spaceBetween: 60
				},
				// when window width is >= 1024px

			},
		}
	}
}
</script>

<style scoped>

</style>
