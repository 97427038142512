<template>
	<section class="hero" :class="{[`hero--${modificator}`]: modificator}">
		<div class="container">
			<div class="hero__content">
				<div class="hero__main-col">
					<h1 class="hero__title" v-html="title"></h1>
					<div class="hero__intro" v-html="intro"></div>
					<ul class="hero__links">
						<li v-for="(link, i) in links" :key="`hero_link_${i}`">
							<a :href="link.url" :target="link.target">
								{{ link.text }}
							</a>
						</li>
					</ul>
					<a class="btn hero__btn" href="#contact"  @click.prevent="onClickContacts">Contact us</a>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "HeroSection",
	props: {
		title: {
			type: String,
			default: ''
		},
		intro: {
			type: String,
			default: ''
		},
		modificator: {
			type: String,
			default: ''
		},
		links: {
			type: Array,
			default: () => []
		},
		visible: {
			type: Boolean,
			default: true
		}
	},
  methods: {
    onClickContacts(e) {
      const anchor = document.querySelector(e.target.getAttribute("href"));

      this.menuVisible = false;
      this.$smoothScroll({
        updateHistory: false,
        scrollTo: anchor,
      });
    }
  }
}
</script>
