<template>
	<div class="layout" :class="`layout--${pathName}`">
		<header class="layout__header header">
			<div class="container">
			<div class="header__content">
        <router-link :to="{name: 'home'}">
				  <img class="header__logo" src="~@/assets/img/logo_tinycheck.svg" alt="">
        </router-link>
				<nav class="header__nav" :class="{visible: menuVisible}">
					<button class="header__btn header__btn--close" @click="menuVisible = false">
						<svg width="25" height="25">
							<use xlink:href="~@/assets/img/sprite.svg#cross"></use>
						</svg>
					</button>
					<router-link class="header__nav-item" :to="{name: 'home'}" @click="menuVisible = false">How it works</router-link>
					<router-link class="header__nav-item" :to="{name: 'about'}" @click="menuVisible = false">About</router-link>
					<router-link class="header__nav-item" :to="{name: 'materials'}" @click="menuVisible = false">Resources</router-link>
					<router-link class="header__nav-item" :to="{name: 'faq'}" @click="menuVisible = false">FAQ</router-link>
					<a class="header__nav-item" href="#contact" @click.prevent="onClickContacts">Contact us</a>
				</nav>
				<button class="header__btn header__btn--burger" @click="menuVisible = true">
					<svg width="38" height="25">
						<use xlink:href="~@/assets/img/sprite.svg#burger"></use>
					</svg>
				</button>
			</div>
			</div>

		</header>
		<main class="layout__main">
			<router-view/>
		</main>
		<footer class="layout__footer footer">
			<div class="container">
				<div class="footer__content">
					<div class="footer__logo">
						<svg class="footer__logo-pic" width="240" height="42">
							<use xlink:href="~@/assets/img/sprite.svg#tinycheck-logo"></use>
						</svg>
					</div>

					<div class="footer__text-wrap">
						<p class="footer__text">
							TinyCheck is&nbsp;a&nbsp;free, safe and easy tool to&nbsp;check your devices for stalkerware and spyware.
						</p>
						<p class="footer__copyright">
							© 2022 TinyCheck. All Rights Reserved
						</p>
					</div>

					<div class="footer__contacts">
						<p class="footer__contacts-item">
							<svg class="footer__contacts-pic" width="32" height="32">
								<use xlink:href="~@/assets/img/sprite.svg#github-logo"></use>
							</svg>
							<a href="https://github.com/KasperskyLab/TinyCheck" target="_blank">Contribute on GitHub</a>
						</p>
						<p class="footer__contacts-item">
							<svg class="footer__contacts-pic" width="32" height="32">
								<use xlink:href="~@/assets/img/sprite.svg#letter"></use>
							</svg>
							<a href="mailto:info@tiny-check.com">info@tiny-check.com</a>
						</p>
					</div>


				</div>
			</div>
		</footer>
	</div>
</template>
<script>
export default {
	data() {
		return {
			menuVisible: false
		}
	},
	computed: {
		pathName() {
			return this.$route.name
		}
	},
	watch: {
		menuVisible(newVal) {
			if (newVal) {
				document.querySelector('body').classList.add('fixed')
			} else {
				document.querySelector('body').classList.remove('fixed')
			}
		}
	},
  methods: {
    onClickContacts(e) {
      const anchor = document.querySelector(e.target.getAttribute("href"));

      this.menuVisible = false;
      this.$smoothScroll({
        updateHistory: false,
        scrollTo: anchor,
      });
    }
  }
}
</script>
