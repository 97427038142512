<template>
  <div
    ref="feedback"
    class="feedback"
    :class="{ [`feedback--${theme}`]: theme }"
    id="contact"
  >
    <div class="container">
      <div class="feedback__content">
        <div class="feedback__left-col">
          <div class="feedback__form" v-if="!formSubmitted">
            <h3 class="feedback__form-title">
              Need more information&nbsp;&mdash; contact&nbsp;us!
            </h3>
            <v-form
              class="form"
              :validation-schema="formSchema"
              @submit="submit"
              v-slot="{ meta }"
            >
              <div class="input form__input">
                <v-field
                  class="input__control"
                  name="name"
                  id="name"
                  placeholder="Name / Organization"
                />
                <v-error-message class="input__error" name="name" />
              </div>
              <div class="input form__input">
                <v-field
                  v-maska="'+####################'"
                  class="input__control"
                  name="phone"
                  id="phone"
                  placeholder="Phone Number"
                />
                <v-error-message class="input__error" name="phone" />
              </div>
              <div class="input form__input">
                <v-field
                  class="input__control"
                  name="email"
                  id="email"
                  placeholder="E-mail"
                />
                <v-error-message class="input__error" name="email" />
              </div>
              <div class="input form__input">
                <v-field
                  as="textarea"
                  class="input__control input__control_textarea"
                  name="comment"
                  id="comment"
                  rows="6"
                  placeholder="If you have any questions about TinyCheck, wish to contribute to the development or cooperate with us, please reach out. "
                />
                <v-error-message class="input__error" name="comment" />
              </div>
              <button
                class="btn feedback__form-btn"
                type="submit"
                :disabled="!meta.valid || !meta.dirty"
              >
                Submit
              </button>
              <v-field 
                as="text" 
                id="g-recaptcha-response" 
                name="g-recaptcha-response"
              />
            </v-form>
          </div>
          <div v-else class="form__success">
            <h3 class="feedback__form-title">Thank you for contacting us!</h3>
            <p class="feedback__form-note">
              Someone from our team will be in touch very soon.
            </p>
            <img
              class="feedback__success-pic"
              src="~@/assets/img/form__success.png"
              alt=""
            />
            <p class="feedback__form-note-addtnl">
              Сheck out our <router-link :to="{name: 'materials'}">Recources page</router-link> to
              get more information about TinyCheck.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form, ErrorMessage } from "vee-validate";
import { formSchema } from "@/assets/js/validation";
import apiClient from "@/assets/js/apiClient";
import { submit } from "dom7";

export default {
  name: "FeedbackForm",
  components: {
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },
  props: {
    theme: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formSchema,
      form: {
        name: "",
        email: "",
        phone: "",
        comment: "",
      },
      formSubmitted: false,
    };
  },
  methods: {
    async submit(form) {
      try {
        await this.$recaptchaLoaded();
        const recaptchaToken = await this.$recaptcha('homepage');
        const result = await apiClient.sendRequest({...form, recaptchaToken});
        if (result?.success || true) {
          this.formSubmitted = true;
          this.$smoothScroll({
            updateHistory: false,
            scrollTo: this.$refs.feedback,
          });
        }
      } catch (e) {
        console.error(e)
      }
    },
  },
};
</script>

<style scoped></style>
