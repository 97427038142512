<template>
  <div class="home">
		<hero-section :title="title" :intro="intro" :links="links" />
		<how-works />
		<video-carousel :slides="stalkwareSlides" :text-content="stalkwareText" />
		<our-partners :slides="partnerSlides" />
		<feedback-form />
  </div>
</template>

<script>
import VideoCarousel from "@/components/VideoCarousel";
import OurPartners from "@/components/OurPartners";
import FeedbackForm from "@/components/FeedbackForm";
import HeroSection from "@/components/HeroSection";
import HowWorks from "@/components/HowWorks";

export default {
  name: 'HomeView',
	components: {HowWorks, HeroSection, VideoCarousel, OurPartners, FeedbackForm},
	data() {
		return {
			title: 'Untraceable stalkerware detection',
			intro: `
					<p>TinyCheck is&nbsp;a&nbsp;free, safe and easy tool to&nbsp;check your devices for stalkerware and monitoring apps</p>
					<p>It&nbsp;was created by&nbsp;Kaspersky experts upon the request of&nbsp;organizations helping victims of&nbsp;domestic abuse. As&nbsp;an&nbsp;open-source tool, it&nbsp;is&nbsp;supported and updated by&nbsp;Kaspersky and the wider cybersecurity community.</p>
				`,
			links: [
				{
					url: "https://github.com/KasperskyLab/TinyCheck#readme",
					text: "TinyCheck source code",
					target: "_blank"
				}
			],
			stalkwareSlides: [
				{
					pic: require('@/assets/img/what_slide1.jpg'),
					videoId: 'zLtfoCw16Z0',
					text: 'What is stalkerware'
				},
				{
					pic: require('@/assets/img/what_slide2.jpg'),
					videoId: 'CbCY5lYZY5o',
					text: 'Why should you care about stalkerware'
				}
			],
			stalkwareText: {
				title: 'What is stalkerware',
				text: `
					<p>Stalkerware is commercial software that allows its users to&nbsp;spy on&nbsp;other people unnoticed. It&nbsp;is&nbsp;often used to&nbsp;abuse the privacy of&nbsp;current or&nbsp;former intimate partners.<p/>
					<p>The stalker can remotely monitor all device activities, including web history, geolocation, text messages, photos, voice calls and more.</p>
					`
			},
			partnerSlides: [
				{
					link: "https://stopviolenciadegenerodigital.com/",
					pic: require('@/assets/img/partners_1-1.png'),
					hasQuotas: true,
					html: `
						<p>I have worked with gender violence associations for many years. TinyCheck is just what victims of abuse and the general population need. TinyCheck is similar to an antigens test: it’s a quick, economic and reliable test to perform the initial inspection of a phone to identify any infection</p>
						<div><b> Bruno Pérez Juncá</b>,</div>
						<p style="font-size: 16px; line-height: 20px; padding-top: 8px">Honorary member of the Stop Gender Violence Association (Associació Stop Violència de Gènere Digital) in Spain.</p>

					`
				},
				{
					pic: require('@/assets/img/partnership.svg'),
					hasQuotas: false,
					html: `
						<h3>Become our partner</h3>
						<p><b>TinyCheck</b> is a free and safe tool. Help us protect victims of domestic violence and stalking, by making TinyCheck more accessible.</p>
						<p><b>Fill in the form below to become our partner!</b></p>
					`
				}
			]
		}
	}
}
</script>
