<template>
  <div class="materials-page">
		<hero-section :title="title" :intro="intro" modificator="materials" />
		<section class="materials">
			<div class="container">
				<h2 class="materials__title">Useful materials for partners</h2>
				<h3 class="materials__subtitle">TinyCheck is&nbsp;a&nbsp;free, safe and untraceable tool against stalkerware. Help&nbsp;us make TinyCheck accessible to&nbsp;all:</h3>
				<ul class="materials__list">
					<li class="materials__item" v-for="material in materials">
						<div class="materials__item-pic-wrap">
							<img :src="material.pic" alt="" class="materials__item-pic">
						</div>
						<div class="materials__item-main">
							<h3 class="materials__item-title" v-html="material.text"></h3>
							<a :href="material.link" download class="btn materials__btn" :class="{btn_disabled: !material.link}">
                <template v-if="material.link">
                  <svg width="32" height="32">
                    <use xlink:href="~@/assets/img/sprite.svg#download"></use>
                  </svg>
                  <span>Download</span>
                </template>
								<span v-else>Coming soon</span>
							</a>
						</div>
					</li>
				</ul>
			</div>
		</section>
		<material-video :text-content="about" :video="aboutVideo"/>
		<feedback-form theme="light" />
  </div>
</template>
<script>
import FeedbackForm from "@/components/FeedbackForm";
import HeroSection from "@/components/HeroSection";
import MaterialVideo from "@/components/MaterialVideo";

export default {
	name: 'MaterialView',
	components: {FeedbackForm, HeroSection, MaterialVideo},
	data() {
		return {
			title: 'Learn more about TinyCheck',
			intro: 'Download and share the materials below. Please let&nbsp;us know if&nbsp;you need more documentation.',
			materials: [
				{
					text: 'TinyCheck presentation for&nbsp;partners',
					link: '/assets/pdf/materials/TinyCheck-Presentation-for-Partners-2022.pdf',
					pic: require('@/assets/img/materials/presentation.png')
				},
				{
					text: 'TinyCheck User Manual (How to&nbsp;Install & Use)',
					link: '',
					pic: require('@/assets/img/materials/user_manual.png')
				},
				{
					text: 'Informational leaflets for&nbsp;Victims and&nbsp;Partners',
					link: '',
					pic: require('@/assets/img/materials/leaflets.png')
				}
			],
			textTitle: '',
			about: {
				title: 'Why should you care about stalkerware',
				text: '<p>Help&nbsp;us raise awareness and protect the victims of&nbsp;domestic violence and stalking.</p> <p>Consider sharing the video below with your network.</p>'
			},
			aboutVideo: {
				pic: require('@/assets/img/what_slide2.jpg'),
				videoId: 'CbCY5lYZY5o',
				text: 'Why should you care about stalkerware'
			}
		}
	}
}
</script>
